import React from 'react'
import styled from 'styled-components'
import {colors, fontfamily} from '../lib/variables'
import {Link} from 'gatsby'

import Button from './button'
import Heading from './heading'

const StyledBasePortableText = styled.div`
  max-width: 640px;
  margin: 0 auto;
  h2,
  h3,
  h4{
    text-align: left;
    font-family: ${fontfamily.jaRounded};
    margin-bottom: 2.4rem;
    padding-top: 3.6rem;
    color: ${colors.navy};
    position: relative;
    padding-bottom: 0.8rem;
    font-weight: 700;
    & :after{
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      background: ${colors.yellow};
      height: 2px;
      width: 100%;
      border-radius: 4px;
    }
  }

  h2{
    font-size: 2.0rem;
  }
  h3{
    font-size: 1.8rem;
    padding-top: 2.4rem;
    margin-bottom: 1.6rem;
    padding-bottom: 0rem;
    & :after{
      display: none;
    }
  }
  h4{
    font-size: 1.6rem;
    padding-top: 1.8rem;
    margin-bottom: 1.4rem;
    padding-bottom: 0rem;
    & :after{
      display: none;
    }
  }

  ul,
  ol{
    font-size: 1.6rem;
    list-style-position: outside;
    margin: 0 0 0 2.6rem;
    padding: 0;
    line-height: 1.75;
  }
  li{
    margin: 0 0 2.4rem 0;
  }
  strong,
  bold{
    color: ${colors.navy};
  }
 `

const Section = styled.section`
  display: block;
  margin: 0 0 4rem 0;
`

const StyledLink = styled(props => <Link {...props} />)`
  color: ${colors.blue};
  font-weight: 400;
  font-size: 1.6rem;
`

const ExternalLink = styled.a`
  color: ${colors.blue};
  font-weight: 400;
  font-size: 1.6rem;
`

const Small = styled.span`
  color: ${colors.lightBlack};
  font-weight: 400;
  font-size: 1.4rem;
  display: block;
`

const Index = props => {
  return (
    <>
      <StyledBasePortableText>
        <Section>
          <Heading ja='NY育英サマースクール&キャンプ2020' en='Summer School & Camp 2020' />
          <h2>サマーキャンプのお申し込みは終了いたしました</h2>
          <p>第二タームを含む全てのお申込みは8月26日をもって終了いたしました。</p>
          <p>たくさんのご参加ありがとうございました。ウィンターキャンプや来年のサマーキャンプでまたお会いできることを楽しみにしております。</p>
          <p>第2タームクラス・MeetURLは<StyledLink to='/summer-2020/term2/classes/'>第2タームクラス確認</StyledLink>よりご確認いただけます。</p>
        </Section>

      </StyledBasePortableText>
    </>
  )
}
export default Index
